var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u(
          [
            {
              key: "itemCutPicSlot",
              fn: function(row) {
                return _c("div", {}, [
                  _c("img", {
                    staticClass: "w-500",
                    attrs: { src: row.itemImage, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.handlePreviewImg([row.itemImage], 0)
                      }
                    }
                  }),
                  _c("div", { staticClass: "mt-10 color-gray" }, [
                    _vm._v(
                      " 【" +
                        _vm._s(row.id) +
                        "】 " +
                        _vm._s(row.coinName) +
                        " "
                    ),
                    row.sourceType
                      ? _c(
                          "span",
                          {
                            class: _vm.computedSourceTypeListMapNameClassType(
                              row.sourceType
                            ).color
                          },
                          [
                            _vm._v(
                              "【" +
                                _vm._s(
                                  _vm.computedSourceTypeListMapNameClassType(
                                    row.sourceType
                                  ).name
                                ) +
                                "】"
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              }
            },
            {
              key: "itemCutPicImgSlot",
              fn: function(row) {
                return _c("div", {}, [
                  _c("img", {
                    staticClass: "w-100",
                    attrs: { src: row.cutItemImage, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.handlePreviewImg([row.cutItemImage], 0)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-10" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.handleCheckModelLabel(row, "")
                            }
                          }
                        },
                        [_vm._v("模型检测")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-10" },
                    [
                      _c(
                        "a-card",
                        { attrs: { title: "模型检测结果", bordered: false } },
                        [
                          row.recoModelCategory
                            ? _c("p", [
                                _vm._v(" 类型： "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-blue font-weight-bold",
                                    class:
                                      _vm.computedRecoModelCategory(
                                        row.recoModelCategory
                                      ).category !== row.coinType
                                        ? "color-red"
                                        : ""
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.computedRecoModelCategory(
                                          row.recoModelCategory
                                        ).categoryDesc
                                      ) + "【"
                                    ),
                                    _c("span", { staticClass: "color-green" }, [
                                      _vm._v(_vm._s(row.categoryPercentage))
                                    ]),
                                    _vm._v("】 ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          row.clartiyRecoScore
                            ? _c("p", [
                                _vm._v(" 清晰度： "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-blue font-weight-bold",
                                    class:
                                      row.clartiyRecoValue !== row.clarity - 1
                                        ? "color-red"
                                        : ""
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.coinAngleRustClarity.clarity[
                                            row.clartiyRecoValue
                                          ]
                                        ) +
                                        " 【"
                                    ),
                                    _c("span", { staticClass: "color-green" }, [
                                      _vm._v(_vm._s(row.clartiyRecoScore))
                                    ]),
                                    _vm._v("】 【"),
                                    _c("span", { staticClass: "color-green" }, [
                                      _vm._v(_vm._s(row.clartiyModelVersion))
                                    ]),
                                    _vm._v("】 ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          row.angleRecoScore
                            ? _c("p", [
                                _vm._v(" 角度： "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-blue font-weight-bold",
                                    class:
                                      row.angleRecoValue !== row.angle - 1
                                        ? "color-red"
                                        : ""
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.coinAngleRustClarity.angle[
                                            row.angleRecoValue
                                          ]
                                        ) +
                                        " 【"
                                    ),
                                    _c("span", { staticClass: "color-green" }, [
                                      _vm._v(_vm._s(row.angleRecoScore))
                                    ]),
                                    _vm._v("】 【"),
                                    _c("span", { staticClass: "color-green" }, [
                                      _vm._v(_vm._s(row.angleModelVersion))
                                    ]),
                                    _vm._v("】 ")
                                  ]
                                )
                              ])
                            : _vm._e(),
                          row.rustRecoScore
                            ? _c("p", [
                                _vm._v(" 锈迹： "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "color-blue font-weight-bold",
                                    class:
                                      row.rustRecoValue !== row.rust - 1
                                        ? "color-red"
                                        : ""
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.coinAngleRustClarity.rust[
                                            row.rustRecoValue
                                          ]
                                        ) +
                                        " 【"
                                    ),
                                    _c("span", { staticClass: "color-green" }, [
                                      _vm._v(_vm._s(row.rustRecoScore))
                                    ]),
                                    _vm._v("】 【"),
                                    _c("span", { staticClass: "color-green" }, [
                                      _vm._v(_vm._s(row.rustModelVersion))
                                    ]),
                                    _vm._v("】 ")
                                  ]
                                )
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ])
              }
            },
            {
              key: "itemConfirmStatusSlot",
              fn: function(row) {
                return _c("div", {}, [
                  _c(
                    "div",
                    { class: _vm.confirmStatusTypeClassType[row.isConfirm] },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.computedConfirmStatusTypeMapText(row.isConfirm)
                        )
                      )
                    ]
                  ),
                  row.isConfirm === 0
                    ? _c(
                        "div",
                        { staticClass: "mt-30" },
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确定标记为不确定？",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleNotConfirm(row.id, -1)
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                { attrs: { type: "danger", size: "small" } },
                                [_vm._v("不确定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  [1, -1].includes(row.isConfirm)
                    ? _c(
                        "div",
                        { staticClass: "mt-30" },
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确定标记为人工确认？",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleNotConfirm(row.id, 10)
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                { attrs: { type: "primary", size: "small" } },
                                [_vm._v("人工确认")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              }
            },
            {
              key: "itemCoinTypeSlot",
              fn: function(row) {
                return _c(
                  "div",
                  {},
                  [
                    row.coinType
                      ? _vm._l(_vm.coinTypeList, function(tag, key) {
                          return _c(
                            "a-popconfirm",
                            {
                              key: key,
                              attrs: {
                                title:
                                  "重新修改类型会覆盖之前的标注记录，需要重新标注！，是否确定修改？",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleConfirm(tag, "coinType", row)
                                }
                              }
                            },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticClass:
                                    "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                                  class:
                                    tag.value === row.coinType
                                      ? "color-blue"
                                      : "",
                                  attrs: {
                                    color:
                                      tag.value === row.coinType ? "blue" : ""
                                  }
                                },
                                [_vm._v(_vm._s(tag.name))]
                              )
                            ],
                            1
                          )
                        })
                      : _vm._l(_vm.coinTypeList, function(tag, key) {
                          return _c(
                            "a-tag",
                            {
                              key: key,
                              staticClass:
                                "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                              class:
                                tag.value === row.coinType ? "color-blue" : "",
                              attrs: {
                                color: tag.value === row.coinType ? "blue" : ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleConfirm(tag, "coinType", row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(tag.name))]
                          )
                        })
                  ],
                  2
                )
              }
            },
            {
              key: "itemImgFrontOrBackSlot",
              fn: function(row) {
                return row.coinType
                  ? _c("div", {}, [
                      _c(
                        "div",
                        { staticClass: "mt-10" },
                        [
                          _c(
                            "div",
                            { staticClass: "color-blue font-weight-bold" },
                            [
                              _vm._v(_vm._s(row.coinName) + " "),
                              row.coinPercentage
                                ? _c("span", { staticClass: "color-green" }, [
                                    _vm._v(
                                      "（" + _vm._s(row.coinPercentage) + "）"
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "a-button",
                            {
                              staticClass: "mt-10",
                              attrs: {
                                type: "primary",
                                size: "small",
                                icon: "edit"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeEditor(row, "coin")
                                }
                              }
                            },
                            [_vm._v("修改币种")]
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              }
            },
            {
              key: "itemTagByFrontOrBackSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", { staticClass: "mb-10 color-red" }, [
                          _vm._v("正反面")
                        ]),
                        _vm._l(_vm.modelLabelMapJson["frontBackSide"], function(
                          tag,
                          key
                        ) {
                          return _c(
                            "a-tag",
                            {
                              key: key,
                              staticClass:
                                "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                              class:
                                tag.value === row.frontBackSide
                                  ? "color-blue"
                                  : "",
                              attrs: {
                                color:
                                  tag.value === row.frontBackSide ? "blue" : ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleConfirm(
                                    tag,
                                    "frontBackSide",
                                    row
                                  )
                                }
                              }
                            },
                            [_vm._v(_vm._s(tag.name))]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgQualitySlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", { staticClass: "mb-10 color-red" }, [
                          _vm._v("清晰度")
                        ]),
                        _vm._l(_vm.modelLabelMapJson["clarity"], function(
                          tag,
                          key
                        ) {
                          return _c(
                            "a-tag",
                            {
                              key: key,
                              staticClass:
                                "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                              class:
                                tag.value === row.clarity ? "color-blue" : "",
                              attrs: {
                                color: tag.value === row.clarity ? "blue" : ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleConfirm(tag, "clarity", row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(tag.name))]
                          )
                        }),
                        _c(
                          "div",
                          { staticClass: "mt-30" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleCheckModelLabel(
                                      row,
                                      "clartiy"
                                    )
                                  }
                                }
                              },
                              [_vm._v("清晰度检测")]
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgAngleSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", { staticClass: "mb-10 color-red" }, [
                          _vm._v("角度")
                        ]),
                        _vm._l(_vm.modelLabelMapJson["angle"], function(
                          tag,
                          key
                        ) {
                          return _c(
                            "a-tag",
                            {
                              key: key,
                              staticClass:
                                "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                              class:
                                tag.value === row.angle ? "color-blue" : "",
                              attrs: {
                                color: tag.value === row.angle ? "blue" : ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleConfirm(tag, "angle", row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(tag.name))]
                          )
                        }),
                        _c(
                          "div",
                          { staticClass: "mt-30" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleCheckModelLabel(
                                      row,
                                      "angle"
                                    )
                                  }
                                }
                              },
                              [_vm._v("角度检测")]
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemImgRustStatusSlot",
              fn: function(row) {
                return row.coinType
                  ? _c(
                      "div",
                      {},
                      [
                        _c("div", { staticClass: "mb-10 color-red" }, [
                          _vm._v("锈迹")
                        ]),
                        _vm._l(_vm.modelLabelMapJson["rust"], function(
                          tag,
                          key
                        ) {
                          return _c(
                            "a-tag",
                            {
                              key: key,
                              staticClass:
                                "cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black",
                              class: tag.value === row.rust ? "color-blue" : "",
                              attrs: {
                                color: tag.value === row.rust ? "blue" : ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handleConfirm(tag, "rust", row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(tag.name))]
                          )
                        }),
                        _c(
                          "div",
                          { staticClass: "mt-30" },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleCheckModelLabel(
                                      row,
                                      "rust"
                                    )
                                  }
                                }
                              },
                              [_vm._v("锈迹检测")]
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  : _vm._e()
              }
            },
            {
              key: "itemModelLabelAdminSlot",
              fn: function(row) {
                return _c("div", {}, [
                  row.sysAdmin
                    ? _c("div", { staticClass: "color-green" }, [
                        _vm._v(_vm._s(row.sysAdmin))
                      ])
                    : _vm._e()
                ])
              }
            },
            {
              key: "itemAuctionSlot",
              fn: function(row) {
                return _c(
                  "div",
                  {},
                  [
                    _c(
                      "a-popconfirm",
                      {
                        attrs: {
                          title: "确定删除当前标记？",
                          "ok-text": "Yes",
                          "cancel-text": "No"
                        },
                        on: {
                          confirm: function($event) {
                            return _vm.handleDelLabel(row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "a-button",
                          { attrs: { type: "danger", size: "small" } },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }
            }
          ],
          null,
          true
        )
      }),
      _c("EditorModelLabelPopup", {
        ref: "editorModelLabelPopupEl",
        on: { editSuccess: _vm.handleEditorSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }