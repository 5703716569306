<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   裁剪图   -->
      <div slot="itemCutPicSlot" slot-scope="row">
        <img class="w-500" :src="row.itemImage" alt="" @click="handlePreviewImg([row.itemImage], 0)">
        <div class="mt-10 color-gray">
          【{{ row.id }}】
          {{ row.coinName }}
          <span v-if="row.sourceType" :class="computedSourceTypeListMapNameClassType(row.sourceType).color">【{{ computedSourceTypeListMapNameClassType(row.sourceType).name }}】</span>
        </div>
      </div>
<!--   裁剪图   -->
      <div slot="itemCutPicImgSlot" slot-scope="row">
        <img class="w-100" :src="row.cutItemImage" alt="" @click="handlePreviewImg([row.cutItemImage], 0)">
        <div class="mt-10"><a-button type="primary" size="small" @click="handleCheckModelLabel(row, '')">模型检测</a-button></div>
        <div class="mt-10">
          <a-card title="模型检测结果" :bordered="false">
            <p v-if="row.recoModelCategory">
              类型：
              <span
                  class="color-blue font-weight-bold"
                  :class="computedRecoModelCategory(row.recoModelCategory).category !== row.coinType ? 'color-red' : ''"
              >{{ computedRecoModelCategory(row.recoModelCategory).categoryDesc }}【<span class="color-green">{{ row.categoryPercentage }}</span>】
              </span>
            </p>
            <p v-if="row.clartiyRecoScore">
              清晰度：
              <span class="color-blue font-weight-bold" :class="row.clartiyRecoValue !== (row.clarity - 1) ? 'color-red' : ''">
                {{ coinAngleRustClarity.clarity[row.clartiyRecoValue] }}
                【<span class="color-green">{{ row.clartiyRecoScore }}</span>】
                【<span class="color-green">{{ row.clartiyModelVersion }}</span>】
              </span>
            </p>
            <p v-if="row.angleRecoScore">
              角度：
              <span class="color-blue font-weight-bold" :class="row.angleRecoValue !== (row.angle - 1) ? 'color-red' : ''">
                {{ coinAngleRustClarity.angle[row.angleRecoValue] }}
                【<span class="color-green">{{ row.angleRecoScore }}</span>】
                【<span class="color-green">{{ row.angleModelVersion }}</span>】
              </span>
            </p>
            <p v-if="row.rustRecoScore">
              锈迹：
              <span class="color-blue font-weight-bold" :class="row.rustRecoValue !== (row.rust - 1) ? 'color-red' : ''">
                {{ coinAngleRustClarity.rust[row.rustRecoValue] }}
                【<span class="color-green">{{ row.rustRecoScore }}</span>】
                【<span class="color-green">{{ row.rustModelVersion }}</span>】
              </span>
            </p>
          </a-card>
        </div>
      </div>
<!--   是否标记   -->
      <div slot="itemConfirmStatusSlot" slot-scope="row">
        <div :class="confirmStatusTypeClassType[row.isConfirm]">{{ computedConfirmStatusTypeMapText(row.isConfirm) }}</div>
        <div class="mt-30" v-if="row.isConfirm === 0">
          <a-popconfirm
              title="确定标记为不确定？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleNotConfirm(row.id, -1)"
          >
            <a-button type="danger" size="small">不确定</a-button>
          </a-popconfirm>
        </div>
        <div class="mt-30" v-if="[1, -1].includes(row.isConfirm)">
          <a-popconfirm
              title="确定标记为人工确认？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleNotConfirm(row.id, 10)"
          >
            <a-button type="primary" size="small">人工确认</a-button>
          </a-popconfirm>
        </div>
      </div>
<!--  钱币类型    -->
      <div slot="itemCoinTypeSlot" slot-scope="row">
        <template v-if="row.coinType">
          <a-popconfirm
              title="重新修改类型会覆盖之前的标注记录，需要重新标注！，是否确定修改？"
              ok-text="Yes"
              cancel-text="No"
              v-for="(tag, key) in coinTypeList"
              :key="key"
              @confirm="handleConfirm(tag, 'coinType', row)"
          >
            <a-tag
                class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
                :class="tag.value === row.coinType ? 'color-blue' : ''"
                :color="tag.value === row.coinType ? 'blue' : ''"
            >{{ tag.name }}</a-tag>
          </a-popconfirm>
        </template>
        <template v-else>
          <a-tag
              v-for="(tag, key) in coinTypeList"
              :key="key"
              class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
              :class="tag.value === row.coinType ? 'color-blue' : ''"
              :color="tag.value === row.coinType ? 'blue' : ''"
              @click="handleConfirm(tag, 'coinType', row)"
          >{{ tag.name }}</a-tag>
        </template>
      </div>
<!--  正面面文    -->
      <div slot="itemImgFrontOrBackSlot" slot-scope="row" v-if="row.coinType">
        <div class="mt-10">
          <div class="color-blue font-weight-bold">{{ row.coinName }} <span class="color-green" v-if="row.coinPercentage">（{{ row.coinPercentage }}）</span> </div>
          <a-button
              class="mt-10" type="primary"
              size="small" icon="edit"
              @click="handleChangeEditor(row, 'coin')"
          >修改币种</a-button>
        </div>
      </div>
<!--  正反面    -->
      <div slot="itemTagByFrontOrBackSlot" slot-scope="row" v-if="row.coinType">
        <div class="mb-10 color-red">正反面</div>
        <a-tag
            v-for="(tag, key) in modelLabelMapJson['frontBackSide']"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag.value === row.frontBackSide ? 'color-blue' : ''"
            :color="tag.value === row.frontBackSide ? 'blue' : ''"
            @click="handleConfirm(tag, 'frontBackSide', row)"
        >{{ tag.name }}</a-tag>
      </div>
<!--   清晰度   -->
      <div slot="itemImgQualitySlot" slot-scope="row" v-if="row.coinType">
        <div class="mb-10 color-red">清晰度</div>
        <a-tag
            v-for="(tag, key) in modelLabelMapJson['clarity']"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag.value === row.clarity ? 'color-blue' : ''"
            :color="tag.value === row.clarity ? 'blue' : ''"
            @click="handleConfirm(tag, 'clarity', row)"
        >{{ tag.name }}</a-tag>
        <div class="mt-30"><a-button type="primary" size="small" @click="handleCheckModelLabel(row, 'clartiy')">清晰度检测</a-button></div>
      </div>
<!--   角度   -->
      <div slot="itemImgAngleSlot" slot-scope="row" v-if="row.coinType">
        <div class="mb-10 color-red">角度</div>
        <a-tag
            v-for="(tag, key) in modelLabelMapJson['angle']"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag.value === row.angle ? 'color-blue' : ''"
            :color="tag.value === row.angle ? 'blue' : ''"
            @click="handleConfirm(tag, 'angle', row)"
        >{{ tag.name }}</a-tag>
        <div class="mt-30"><a-button type="primary" size="small" @click="handleCheckModelLabel(row, 'angle')">角度检测</a-button></div>
      </div>
<!--   锈迹   -->
      <div slot="itemImgRustStatusSlot" slot-scope="row" v-if="row.coinType">
        <div class="mb-10 color-red">锈迹</div>
        <a-tag
            v-for="(tag, key) in modelLabelMapJson['rust']"
            :key="key"
            class="cur-pot mb-5 w-80 h-30 mr-10 font-size-14 text-center color-black"
            :class="tag.value === row.rust ? 'color-blue' : ''"
            :color="tag.value === row.rust ? 'blue' : ''"
            @click="handleConfirm(tag, 'rust', row)"
        >{{ tag.name }}</a-tag>
        <div class="mt-30"><a-button type="primary" size="small" @click="handleCheckModelLabel(row, 'rust')">锈迹检测</a-button></div>
      </div>
<!-- 标注员     -->
      <div slot="itemModelLabelAdminSlot" slot-scope="row">
        <div class="color-green" v-if="row.sysAdmin">{{ row.sysAdmin }}</div>
      </div>
<!--  操作    -->
      <div slot="itemAuctionSlot" slot-scope="row">
        <a-popconfirm
            title="确定删除当前标记？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDelLabel(row.id)"
        >
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
<!--  编辑相关  -->
    <EditorModelLabelPopup ref="editorModelLabelPopupEl" @editSuccess="handleEditorSuccess"/>
  </div>
</template>
<script>
import {
  coin_angle_rust_clarity_check,
  COIN_TYPE_LIST, coinTypeMapText,
  confirmStatusTypeClassType, confirmStatusTypeMapText,
  duibanModelLabelTableColumns, MODEL_LABEL_MAP_JSON, source_type_list_map_name_class_type,
} from "@/views/cmsPage/versionManage/duibanModelLabel/_data"
import EditorModelLabelPopup
  from "@/views/cmsPage/versionManage/duibanModelLabel/_components/EditorModelLabelPopup/index.vue"
import {
  delDuiBanModelLabelListApi, getCheckModelLabelApi, getCheckModelLabelRustOrAngleOrClarityApi,
  postModelLabelInfoApi,
  postModelLabelNotConfirmApi
} from "@/views/cmsPage/versionManage/duibanModelLabel/_apis"

export default {
  props: ['pagination', 'list'],
  components: { EditorModelLabelPopup },
  data() {
    return {
      confirmStatusTypeClassType,
      tableList: duibanModelLabelTableColumns,
      modelLabelMapJson: MODEL_LABEL_MAP_JSON,
      coinTypeList: COIN_TYPE_LIST,
      coinAngleRustClarity: coin_angle_rust_clarity_check,
      sourceTypeListMapNameClassType: source_type_list_map_name_class_type,
    }
  },
  computed: {
    computedRecoModelCategory() {
      return (jsonStr) => {
        if (jsonStr) {
          return JSON.parse(jsonStr)
        }
        return {}
      }
    },
    computedConfirmStatusTypeMapText() {
      return (value) => confirmStatusTypeMapText(value)
    },
    computedSourceTypeListMapNameClassType() {
      return (type) => {
        if (type) {
          return this.sourceTypeListMapNameClassType[type]
        } else {
          return {
            color: '',
            name: ''
          }
        }
      }
    },
    computedCoinTypeMapText() {
      return (value) => coinTypeMapText(value)
    },
  },
  methods: {
    /** 模型检测 */
    async handleCheckModelLabel(row, type) {
      this.$loading.show()
      let res
      if (type) {
        res = await getCheckModelLabelRustOrAngleOrClarityApi(row.id, type)
      } else {
        res = await getCheckModelLabelApi(row.id)
      }
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info(res.message || '成功')
      this.handleEditorSuccess({
        id: row.id,
      })
    },
    /** 不确定标记 */
    async handleNotConfirm(id, value) {
      this.$loading.show()
      const res = await postModelLabelNotConfirmApi({id: id, isConfirm: value})
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('标记成功')
      this.handleEditorSuccess()
    },
    /** 删除 */
    async handleDelLabel(id) {
      this.$loading.show()
      const res = await delDuiBanModelLabelListApi(id)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleEditorSuccess({
        id: id,
        type: 'del'
      })
    },
    /** 编辑正面面文 */
    handleChangeEditor(row, type) {
      const mustParams = {
        coinId: row.coinId,
        frontBackSide: row.frontBackSide,
        clarity: row.clarity,
        angle: row.angle,
        rust: row.rust
      }
      this.$refs.editorModelLabelPopupEl.show(row, type, mustParams)
    },
    /** 编辑成功 */
    handleEditorSuccess(data) {
      if (data) {
        this.$emit('success', data)
      } else {
        this.$emit('success')
      }
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 快速操作正反面图 */
    /** 提交数据 */
    async handleConfirm(jsonData, jsonKey, row) {
      if (jsonKey !== 'coinType') {
        if (!row.coinType) return this.$message.warn('请先选择钱币类型')
      }
      const tempCoinType = jsonKey === 'coinType' ? jsonData.value : row.coinType

      let tempMustParams = {coinId: row.coinId, frontBackSide: row.frontBackSide, clarity: row.clarity, angle: row.angle, rust: row.rust}
      if (jsonKey !== 'coinType') {
        tempMustParams[jsonKey] = jsonData.value
      } else {
        tempMustParams = {coinId: 0, frontBackSide: 0, clarity: 0, angle: 0, rust: 0}
      }
      const res = await postModelLabelInfoApi({
        id: row.id,
        coinType: tempCoinType,
        ...tempMustParams,
      })
      if (res.status !== '200') return
      this.handleEditorSuccess({
        id: row.id,
      })
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    }
  }
}
</script>
<style lang="scss" scoped>
</style>