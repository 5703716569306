<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.id"
          class="ml-5 w-200 mb-5"
          placeholder='ID搜索'
      ></a-input>
      <a-select
          @search="searchBigCoinData"
          v-model="params.coinId"
          :filter-option="untils.filterOption"
          showSearch
          placeholder="请选择大币种"
          allowClear
          class="ml-5 w-200 mb-5"
      >
        <a-select-option
            v-for="item of bigCoinList"
            :key="item.id"
            :value="item.id"
        >{{ item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' ) }}</a-select-option>
      </a-select>
      <a-select
          v-model="params.coinType"
          :filter-option="untils.filterOption"
          showSearch
          placeholder="钱币类型"
          allowClear
          class="ml-5 w-200 mb-5"
      >
        <a-select-option
            v-for="item of coinTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          v-model="params.isConfirm"
          :filter-option="untils.filterOption"
          showSearch
          placeholder="选择状态"
          allowClear
          class="ml-5 w-200 mb-5"
      >
        <a-select-option
            v-for="item of confirmStatusTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          v-model="params.frontBackSide"
          :filter-option="untils.filterOption"
          showSearch
          placeholder="正反面选择"
          allowClear
          class="ml-5 w-200 mb-5"
      >
        <a-select-option
            v-for="item of frontBackSideList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
        v-model="params.clarity"
        :filter-option="untils.filterOption"
        showSearch
        placeholder="选择清晰度"
        allowClear
        class="ml-5 w-200 mb-5"
      >
        <a-select-option
          v-for="item of clarityList"
          :key="item.value"
          :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          v-model="params.angle"
          :filter-option="untils.filterOption"
          showSearch
          placeholder="选择角度"
          allowClear
          class="ml-5 w-200 mb-5"
      >
        <a-select-option
            v-for="item of angleList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          v-model="params.rust"
          :filter-option="untils.filterOption"
          showSearch
          placeholder="选择锈迹"
          allowClear
          class="ml-5 w-200 mb-5"
      >
        <a-select-option
            v-for="item of rustList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          v-model="params.isRecoCategory"
          :filter-option="untils.filterOption"
          showSearch
          placeholder="模型类型是否检测"
          allowClear
          class="ml-5 w-200 mb-5"
      >
        <a-select-option
            v-for="item of checkModelStatusList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          v-model="params.isRecoLabelModel"
          :filter-option="untils.filterOption"
          showSearch
          placeholder="模型属性是否检测"
          allowClear
          class="ml-5 w-200 mb-5"
      >
        <a-select-option
            v-for="item of checkModelStatusList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button v-if="!otherSearchParams" type="danger" size="small" class="mb-5" @click="handleAddOtherSearchParams">添加其他搜索条件</a-button>
      <div class="ml-5 mb-5" v-else>
        <a-tag
            color="blue"
            closable
            @close="handleDeleteTag"
            @click="handleAddOtherSearchParams"
        >
          <span>【<span class="color-green font-weight-bold">{{ otherSearchParams.searchTypeText }}</span>】</span>
          <span v-if="otherSearchParams.match">【<span class="color-green font-weight-bold">{{ otherSearchParams.matchText }}</span>】</span>
          <span v-if="otherSearchParams.sort">【<span class="color-green font-weight-bold">{{ otherSearchParams.sortText }}</span>】</span>
          <span v-if="otherSearchParams.score">【<span class="color-green font-weight-bold">{{ otherSearchParams.score }}</span>】</span>
        </a-tag>
      </div>
      <a-button class="mb-5" type="primary" @click="search">搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="handleChangeSuccess"
      />
    </div>
<!--  添加其他搜索条件  -->
    <AddSearchOtherParamsPopup ref="addSearchOtherParamsPopupEl" @addSearch="handleAddSearchParams($event)"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/versionManage/duibanModelLabel/_components/TableList/index.vue"
import {getDuiBanModelLabelListApi} from "@/views/cmsPage/versionManage/duibanModelLabel/_apis"
import {getBigCoinList} from "@/axios/apis"
import untils from "@/untils"
import {
  angleList, checkModelStatusList,
  clarityList, COIN_TYPE_LIST,
  confirmStatusTypeList,
  frontBackSideList, rustList
} from "@/views/cmsPage/versionManage/duibanModelLabel/_data"
import AddSearchOtherParamsPopup
  from "@/views/cmsPage/versionManage/duibanModelLabel/_components/AddSearchOtherParamsPopup/index.vue";

export default {
  components: {
    TableList, AddSearchOtherParamsPopup,
  },
  data() {
    return {
      untils,
      data: [],
      confirmStatusTypeList,
      frontBackSideList,
      checkModelStatusList,
      coinTypeList: COIN_TYPE_LIST,
      clarityList,
      angleList,
      rustList,
      bigCoinList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        id: undefined,
        coinId: undefined,
        isConfirm: undefined,
        frontBackSide: undefined,
        clarity: undefined,
        angle: undefined,
        rust: undefined,
        isRecoCategory: undefined,
        isRecoLabelModel: undefined,
        // 清晰度，角度，锈迹匹配
        clartiyMatch: undefined,
        angleMatch: undefined,
        rustMatch: undefined,
        // 清晰度，角度，锈迹分数排序
        clartiySort: undefined,
        angleSort: undefined,
        rustSort: undefined,
        // 分数搜索
        clartiyScore: undefined,
        angleScore: undefined,
        rustScore: undefined,
      },
      otherSearchParams: undefined,
      otherSearchScoreAutoStatus: false,
    };
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    /** 删除其他搜索条件 */
    async handleDeleteTag() {
      if (this.otherSearchParams.searchType === 'clarity') {
        this.params.clartiyMatch = undefined
        this.params.clartiySort = undefined
        this.params.clartiyScore = undefined
      } else if (this.otherSearchParams.searchType === 'angle') {
        this.params.angleMatch = undefined
        this.params.angleSort = undefined
        this.params.angleScore = undefined
      } else if (this.otherSearchParams.searchType === 'rust') {
        this.params.rustMatch= undefined
        this.params.rustSort = undefined
        this.params.rustScore = undefined
      }
      this.otherSearchParams = undefined
      this.otherSearchScoreAutoStatus = false
      await this.getList()
    },
    /** 添加搜索条件 */
    async handleAddSearchParams(data) {
      this.otherSearchParams = data.otherParams
      this.otherSearchScoreAutoStatus = data.autoStatus
      Object.assign(this.params, data.searchParams)
      this.params.pageNum = 1
      await this.getList()
    },
    /** 添加其他搜索条件 */
    handleAddOtherSearchParams() {
      this.$refs.addSearchOtherParamsPopupEl.show(this.params ,  this.otherSearchParams, this.otherSearchScoreAutoStatus)
    },
    /** 翻页 */
    async handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      await this.getList();
      document.getElementsByClassName('table-content')[0].scrollTop = 0

    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
    /** 修改钱币特征 */
    async handleChangeSuccess(data) {
      if (data) {
        if (data.type === 'del') {
          this.data = this.data.filter(item => {
            return item.id !== data.id
          })
        } else {
          const res = await getDuiBanModelLabelListApi({id: data.id})
          if (res.status !== '200') return;
          this.data.forEach(el => {
            if (el.id === res.data.records[0].id) {
              Object.assign(el, res.data.records[0])
              this.$forceUpdate()
            }
          })
        }
      } else {
        await this.getList()
      }
    },
    /** 获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getDuiBanModelLabelListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
    /** 查询大币种 */
    async searchBigCoinData(str) {
      const res = await getBigCoinList(str)
      if (res.status !== '200') return
      const { records } = res.data;
      this.bigCoinList = records;
    }
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
